import * as Sentry from '@sentry/react';
import arrowDown from 'assets/images/chevron-down.svg';
import Identification from 'assets/images/identification.svg';
import axios from 'axios';
import { WhiteChevronRightIcon } from 'components';
import SigningHeader from 'components/Pages/Signing/Header/Header';
import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import appContext from 'context/app-context';
import { motion } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import styles from './styles.module.css';

export default function IDVerification() {
  const {
    setCurrentPage,
    idNumberVerified,
    signatory,
    setIdNumber,
    email,
    countries,
    setCountry,
    setNotVerified
  } = useContext(appContext);
  const [idNumber, setId] = useState('');
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const IDInput = useRef();
  const [verificationFailed, setVerificationFailed] = useState(false);
  const [userCountry, setUserCountry] = useState('');

  useEffect(() => {
    if (IDInput.current) IDInput.current.focus();
  }, [IDInput]);

  useEffect(() => {
    setId(signatory?.id_number);
    getCurrentLocation();
    if (idNumberVerified) {
      setCurrentPage(4);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idNumberVerified]);

  useEffect(() => {
    setId(signatory?.id_number);
    getCurrentLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatory]);

  const verifyId = async (e) => {
    e.preventDefault();
    setErrors('');
    setIdNumber(idNumber);
    setCountry(userCountry);

    if (userCountry !== 'South Africa') {
      setCurrentPage(8);
      return;
    } else if (!validateIDNumber(idNumber)) {
      setErrors({
        id_number: 'Please enter a valid South African ID Number.'
      });
      return;
    }
    setShowLoader(true);
    try {
      const { data } = await axios.post(`verify-id`, {
        id_number: idNumber,
        country: userCountry,
        code: 'ZA',
        email: email,
        signatory: signatory
      });

      setShowLoader(false);
      if (data.success === true) {
        // upload passport/id document
        // setCurrentPage(8);
        //TODO: remove this
        setCurrentPage(4);
        setShowLoader(false);
      } else {
        setNotVerified(true);
        setCurrentPage(4);
        setShowLoader(false);
        // setCurrentPage(8);
        //setError('Id number verification failed.');
      }

      console.log('idNumber', idNumber, 'data', data);
    } catch (e) {
      setShowLoader(false);
      if (e.response?.status === 422) {
        setErrors(e.response.data.errors);
      } else {
        setCurrentPage(8);
      }
      console.log('idNumber', idNumber, 'idNumberVerified', idNumberVerified);
      Sentry.captureException(e);
    }
  };

  function previousPage() {
    setCurrentPage(2);
  }

  function proceed() {
    console.log('got here');
  }

  const [state, setState] = useState({
    ip: '',
    countryName: '',
    countryCode: '',
    city: '',
    timezone: ''
  });
  //Detect user current location / country
  const getGeoInfo = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data;
        setState({
          ...state,
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone
        });

        if (data.country_name !== '') {
          setUserCountry(data.country_name);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // getGeoInfo();
  }, []);

  function getCurrentLocation() {
    //Detect user current location / country
    if (state.countryName !== '') {
      setUserCountry(state.countryName);
      return;
    }

    if (signatory?.country) {
      setUserCountry(signatory?.country);
      setCountry(signatory?.country);
    } else {
      setUserCountry('South Africa');
      setCountry('South Africa');
    }
  }

  function validateIDNumber(IdNumber) {
    let ex = /^[0-9]{13}$/;
    return ex.test(IdNumber) && controlDigitValidate(IdNumber);
  }

  function controlDigitValidate(idNumber) {
    console.log('here');
    let checkDigit = parseInt(idNumber[12], 10);

    let oddDigitsSum = 0;

    for (let i = 0; i < idNumber.length - 1; i += 2) {
      oddDigitsSum += parseInt(idNumber[i], 10);
    }
    let evenDigits = '';
    for (let j = 1; j < idNumber.length - 1; j += 2) {
      evenDigits += idNumber[j];
    }
    evenDigits = parseInt(evenDigits, 10);
    evenDigits *= 2;
    evenDigits = '' + evenDigits;

    let sumOfEvenDigits = 0;
    for (let k = 0; k < evenDigits.length; k++) {
      sumOfEvenDigits += parseInt(evenDigits[k], 10);
    }
    let total = sumOfEvenDigits + oddDigitsSum;
    let computedCheckDigit = 10 - (total % 10);

    if (computedCheckDigit === 10) {
      computedCheckDigit = 0;
    }
    console.log('lastly', computedCheckDigit, checkDigit);
    return computedCheckDigit === checkDigit;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <SigningHeader previousPage={previousPage} />
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          <div className={styles.main}>
            <div className='text-center pt-5'>
              <div className='pb-2'>
                <img src={Identification} alt='ID' className={styles.icon} />
              </div>
              {!verificationFailed ? (
                <>
                  <p className='fw-500 fs-14'>
                    To verify who you are,
                    <br />
                    we need your ID number
                  </p>
                </>
              ) : (
                <>
                  <p className='fw-500 fs-14'>
                    Verification is temporarily unavailable
                  </p>
                </>
              )}
            </div>
            {!verificationFailed ? (
              <Form onSubmit={(e) => verifyId(e)}>
                <div className='p-b-100 relative text-center'>
                  <div className={styles.centerSelect}>
                    <div className={styles.countrySelected}>
                      {countries && userCountry}
                    </div>
                    <img
                      src={arrowDown}
                      className={styles.arrowDown}
                      alt='country select'
                    />
                    <select
                      className={styles.selectDropdown}
                      onChange={(e) => setUserCountry(e.currentTarget.value)}
                    >
                      {countries.map((country, key) => {
                        return (
                          <option
                            key={key}
                            selected={userCountry === country}
                            value={country}
                          >
                            {country}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <Form.Group className='mb-5 text-center'>
                  <div className='text-center pb-3'>
                    <label className={styles.label}>ID/Passport Number</label>
                  </div>
                  <Form.Control
                    type='text'
                    inputMode='text'
                    defaultValue={idNumber}
                    ref={IDInput}
                    onChange={(e) => setId(e.currentTarget.value)}
                    className={styles.idPassportNumber}
                  />
                  {errors?.id_number ? (
                    <small className={styles.error}>{errors?.id_number}</small>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <div className='text-center'>
                  {showLoader ? (
                    <SpinnerAnimation />
                  ) : (
                    <Button
                      className='btn btn-dark rounded-circle btn-md-rounded pulse-animate'
                      onClick={(e) => verifyId(e)}
                      disabled={!idNumber}
                    >
                      <span className='p-relative' style={{ right: '-2px' }}>
                        <WhiteChevronRightIcon />
                      </span>
                    </Button>
                  )}
                </div>
              </Form>
            ) : (
              <div className='text-center'>
                <p className='fw-700 fs-16'>{idNumber}</p>
                <Form.Group className='mb-4 ps-3 pe-3'>
                  <div className='pb-2 text-center'>
                    <label className={styles.label}>FIRST NAMES</label>
                  </div>
                  <Form.Control type='text' className={styles.input} />
                  {error ? <small className={styles.error}>{error}</small> : ''}
                </Form.Group>
                <Form.Group className='mb-4 ps-3 pe-3'>
                  <div className='text-center pb-2'>
                    <label className={styles.label}>LAST NAME</label>
                  </div>
                  <Form.Control type='text' className={styles.input} />
                </Form.Group>

                <div>
                  {showLoader ? (
                    <SpinnerAnimation />
                  ) : (
                    <Button
                      className='btn btn-dark rounded-circle btn-md-rounded pulse-animate'
                      onClick={(e) => proceed(e)}
                    >
                      <span className='p-relative' style={{ right: '-2px' }}>
                        <WhiteChevronRightIcon />
                      </span>
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}
