import styles from './styles.module.css';
import { motion } from 'framer-motion';
import { Row, Col } from 'react-bootstrap';
import SigningHeader from 'components/Pages/Signing/Header/Header';
import Camera from 'components/CameraCapture/Camera';
import appContext from 'context/app-context';
import { useContext } from 'react';

export default function PageFour() {
  const { setCurrentPage, setSelfieVerified, notVerified } =
    useContext(appContext);

  function previousPage() {
    //Document view
    setCurrentPage(2);
    setSelfieVerified(false);
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <SigningHeader previousPage={previousPage} />
        <Col md={{ span: 6, offset: 3 }}>
          <div className={styles.main}>
            <div className='text-center'>
              <Camera
                onClick={(e) =>
                  notVerified ? setCurrentPage(8) : setCurrentPage(5)
                }
              />
            </div>
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}
